import styled from "styled-components";
import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 440px;

  @media (max-width: 767px) {
    width: 100%;
  }

  & input {
    margin-left: 10px;
    padding: 20px 10px;
    background-color: unset;

    border-bottom: 1px solid var(--line);
    box-sizing: border-box;
    font-size: 16px;
    font-family: var(--ff);

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: initial;
    }
  }

  & button {
    font-family: var(--ff-600);
    font-size: 24px;
    color: var(--orange);
    margin-top: 60px;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    &:hover {
      text-decoration: underline 2px solid var(--orange);
      text-underline-offset: 8px;
      scale: 1.1;
    }

    @media (max-width: 1023px) {
      margin: 60px 0;
    }
    @media (max-width: 767px) {
      margin: 20px 0;
      max-width: unset;
    }
  }
  @media (max-width: 767px) {
    max-width: unset;
  }
`;

const Thanks = styled.div`
  font-family: var(--ff-500);
  font-size: 32px;
  text-align: left;
  line-height: 1;
  letter-spacing: -3px;
  color: var(--orange);
  @media (max-width: 767px) {
    text-align: center;
  }
`;

const initialState = {
  name: "",
  phone: "",
  email: "",
};

const Form = () => {
  const [user, setUser] = useState(initialState);
  const [wasSend, setWasSend] = useState(false);
  const [wasError, setWasError] = useState(false);

  useEffect(() => {}, []);

  const onChange = (e) => {
    const { id, value } = e.target;
    if (id === "phone") {
      const phoneValue = value.replace(/\D/g, "");

      setUser((prevState) => ({
        ...prevState,
        [id]: phoneValue,
      }));
    } else {
      setUser((prevState) => ({
        ...prevState,
        [id]: value || "",
      }));
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_KEY,
        process.env.REACT_APP_MORTGAGE_TEMPLATE,
        {
          to_name: "lid.comfortm.gmail.com",
          email: user.email,
          phone: user.phone,
          from_name: user.name,
        },
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          setWasSend(true);
          setTimeout(() => {
            setWasSend(false);
            setUser(initialState);
          }, 3000);
        },
        (error) => {
          setWasError(true);
        }
      );
  };
  return (
    <FormEl onSubmit={sendEmail}>
      {!wasSend ? (
        <>
          <input
            placeholder="Имя"
            id="name"
            required
            onChange={onChange}
            value={user.name}
          />
          <input
            placeholder="Телефон"
            id="phone"
            required
            type="tel"
            value={user.phone}
            onChange={onChange}
          />
          <input
            placeholder="Email"
            id="email"
            required
            type="email"
            value={user.email}
            onChange={onChange}
          />
          <button type="submit">Отправить</button>
        </>
      ) : (
        <Thanks>
          {wasError ? (
            <>Произошла ошибка. Пожалуйста позвоните нам по номеру</>
          ) : (
            <>
              Ваша заявка отправлена. <br /> Мы скоро с вами свяжемся
            </>
          )}
        </Thanks>
      )}
    </FormEl>
  );
};

export default Form;
