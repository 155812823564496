import React, { useEffect } from "react";
import styled from "styled-components";
import { navigationItems } from "../constants";

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  background: #eb4426f0;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  height: 100vh;
  padding: 0 20px;
  text-align: left;

  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  overflow-y: hidden;

  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 16px;

    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--black);
    text-decoration: none;
    transition: color 0.3s linear;
    & span {
      color: var(--black);
    }

    &:hover {
      color: #343078;
    }
  }
`;

const MobileNavItem = styled.a`
  font-size: 14px;
`;

const MobileMenu = ({ open, setOpen, handleScroll }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [open]);
  return (
    <StyledMenu open={open}>
      {navigationItems.map((link) => (
        <MobileNavItem
          key={link.anchor}
          onClick={() => {
            setOpen(!open);
            handleScroll(link.anchor);
          }}
        >
          {link.text}
          <span>{link.additionalText}</span>
        </MobileNavItem>
      ))}
    </StyledMenu>
  );
};

export default MobileMenu;
