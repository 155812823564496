import React, { useRef, useState } from "react";
import styled from "styled-components";
import logo from "../assets/icons/logo.svg";
import Burger from "./Burger";
import MobileMenu from "./MobileMenu";
import { navigationItems } from "../constants";

const HeaderEl = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0 10px;
  max-width: 1420px;
  padding: 15px;
  margin: 0 auto;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 110px;
  font-size: 14px;
  font-family: var(--ff-500);
  background-color: rgba(30, 30, 30, 0.951);
  @media (max-width: 1023px) {
    height: 70px;
  }
  @media (max-width: 879px) {
    gap: unset;
  }
`;

const Logo = styled.img`
  cursor: pointer;
  width: 134px;
`;
const Navigation = styled.nav`
  display: flex;
  flex: 1;
  justify-content: space-between;
  :hover {
    scale: 1.1;
    transition: all ease-in-out 300ms;
  }
  & span {
    color: var(--orange);
  }
`;
const Navlink = styled.li`
  cursor: pointer;
`;

const MobileRightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  & a {
    font-size: 16px;
  }
  @media (max-width: 374px) {
    gap: 10px;
  }
`;

const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) return;
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

const Header = (props) => {
  const { screenWidth } = props;
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  const handleScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section && sectionId !== "call") {
      const elementPosition =
        section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 108;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else if (sectionId === "call") {
      const phoneNumber = "+79282050645";
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  return (
    <HeaderEl>
      <Logo
        src={logo}
        onClick={() => {
          handleScrollTo("hero");
        }}
        alt="logo"
      />
      {screenWidth < 880 ? (
        <MobileRightPart>
          <a href="tel:+79282050645" target="_blank" rel="noreferrer">
            Позвонить
          </a>
          <div ref={node}>
            <Burger open={open} setOpen={setOpen} />
            <MobileMenu
              open={open}
              setOpen={setOpen}
              handleScroll={handleScrollTo}
            />
          </div>
        </MobileRightPart>
      ) : (
        <Navigation>
          {navigationItems.map((link) => (
            <Navlink
              key={link.anchor}
              onClick={() => {
                handleScrollTo(link.anchor);
              }}
            >
              {link.text}
              <span>{link.additionalText}</span>
            </Navlink>
          ))}
        </Navigation>
      )}
    </HeaderEl>
  );
};

export default Header;
