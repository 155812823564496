import { useEffect, useState } from "react";

import "./App.css";
import Header from "./components/Header";
import Hero from "./layouts/Hero";
import Profits from "./layouts/Profits";
import HowItWorks from "./layouts/HowItWorks";
import Projects from "./layouts/Projects";
import Services from "./layouts/Services";
import Mortgage from "./layouts/Mortgage";
import Feedbacks from "./layouts/Feedbacks";
import Footer from "./components/Footer";
import ActionButton from "./components/actionButton/ActionButton";
import Map from "./components/Map";

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App">
      <Header screenWidth={screenWidth} />
      <main>
        <Hero screenWidth={screenWidth} />
        <Profits />
        <HowItWorks screenWidth={screenWidth} />
        <Projects screenWidth={screenWidth} />
        <Services />
        <Mortgage screenWidth={screenWidth} />
        <Feedbacks screenWidth={screenWidth} />
        <Map />
      </main>

      <Footer screenWidth={screenWidth} />
      <ActionButton />
    </div>
  );
}

export default App;
