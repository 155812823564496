import React from "react";
import styled from "styled-components";
import { Title } from "./Profits";
import {
  projectsForMobile,
  projectsItems1,
  projectsItems2,
} from "../constants";

const ProjectsEl = styled.section`
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 200px;
  @media (max-width: 767px) {
    margin-top: 80px;
  }
`;

const ProjectTitle = styled(Title)`
  text-align: right;
  @media (max-width: 575px) {
    font-size: 32px;
  }
  @media (max-width: 374px) {
    font-size: 28px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 200px;
  @media (max-width: 767px) {
    gap: 100px;
  }
`;

const PartOfContent = styled.div`
  display: grid;
  gap: 0;
  grid-template-columns: 0.8fr 1fr 1fr;
  grid-template-rows: repeat(2, minmax(100px, 500px));
  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(2, minmax(100px, 350px));
  }
`;

const ProjectWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
const ProjectItem = styled.div`
  height: 100%;
  width: 100%;

  &.style-0 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 50px 56px 0;
    border-top: 1px solid var(--line);
    border-bottom: 1px solid var(--line);
    text-align: left;
    @media (max-width: 1023px) {
      padding: 0 35px 20px 0;
      justify-content: unset;
      font-size: 14px;
    }
  }
  &.style-1 {
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.style-2 {
    position: relative;
    padding-left: 20px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .title-2 {
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.style-3 {
    position: relative;
    padding: 115px 85px 65px 45px;
    border-right: 1px solid var(--line);
    &.biggerImg {
      padding: 60px 25px 0 10px;
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .title-3 {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.style-4 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & p {
      display: flex;
      align-items: center;
      height: 50%;
      width: 100%;
      max-width: 280px;
      padding: 0 10px;
      text-align: left;
      font-size: 14px;
    }
    & span {
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 50%;
      background-color: var(--line);
    }
    &.longText {
      p {
        max-width: 390px;
      }
    }
  }
  &.style-5 {
    position: relative;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
      width: 80%;
      height: 80%;
      object-fit: cover;
    }
    .title-5 {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const DownloadButton = styled.button`
  font-family: var(--ff-600);
  font-size: 24px;
  margin-top: 80px;
  cursor: pointer;
  & a {
    color: var(--orange);
    transition: all 500ms ease-in-out;
    scale: 1.1;
    text-underline-offset: 8px;
    text-decoration: underline 2px solid var(--orange);
  }

  @media (max-width: 1023px) {
    margin-top: 60px;
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

const MobileContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 300px;
  }
`;

const Item = styled.div`
  position: relative;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0c0c0cbd;
  }
`;
const Info = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 30px;
  & h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: var(--ff-600);
    font-size: 28px;

    & :first-child {
      color: var(--orange);
      font-size: 60px;
      line-height: 1;
    }
    & :last-child {
      color: var(--orange);
      font-size: 24px;
    }
  }
  & p {
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
`;

const Projects = ({ screenWidth }) => {
  return (
    <ProjectsEl id="projects">
      <ProjectTitle>Проектирование и Строительство</ProjectTitle>
      {screenWidth > 767 ? (
        <Content>
          <PartOfContent>
            {projectsItems1.map((project, index) => (
              <ProjectWrapper key={project.text}>
                <ProjectItem className={`style-${index}`}>
                  {index === 0 && (
                    <div className="projectTitle">
                      <h3>01</h3>
                      <h4>Дом</h4>
                    </div>
                  )}
                  {project.text && <p>{project.text}</p>}
                  {project.text && project.additionalText && (
                    <>
                      <p>{project.additionalText}</p>
                      <span />
                    </>
                  )}
                  {project.src && <img src={project.src} alt="" />}
                </ProjectItem>
              </ProjectWrapper>
            ))}
          </PartOfContent>
          <PartOfContent>
            {projectsItems2.map((project, index) => (
              <ProjectWrapper key={project.text}>
                <ProjectItem
                  className={`style-${index} ${index === 4 ? "longText" : ""} ${
                    index === 3 ? "biggerImg" : ""
                  }`}
                >
                  {index === 0 && (
                    <div className={`projectTitle title-${index}`}>
                      <h3>02</h3>
                      <h4>Дача</h4>
                    </div>
                  )}
                  {index === 2 && (
                    <div className={`projectTitle title-${index}`}>
                      <h3>03</h3>
                      <h4>Баня</h4>
                    </div>
                  )}
                  {index === 3 && (
                    <div className={`projectTitle title-${index}`}>
                      <h3>05</h3>
                      <h4>Беседка</h4>
                    </div>
                  )}
                  {index === 5 && (
                    <div className={`projectTitle title-${index}`}>
                      <h3>04</h3>
                      <h4>Гараж</h4>
                    </div>
                  )}
                  {project.text && <p>{project.text}</p>}
                  {project.text &&
                    project.additionalText &&
                    screenWidth > 1023 && (
                      <>
                        <p>{project.additionalText}</p>
                        <span />
                      </>
                    )}
                  {project.src && <img src={project.src} alt="" />}
                </ProjectItem>
              </ProjectWrapper>
            ))}
          </PartOfContent>
        </Content>
      ) : (
        <MobileContent>
          {projectsForMobile.map((project) => (
            <Item key={project.description}>
              <Info>
                {project.title && (
                  <h3>
                    <span>{project.count}</span>
                    <span>{project.title}</span>
                  </h3>
                )}
                <p>{project.description}</p>
              </Info>
              <img src={project.src} alt={project.title} />
              <div className="background" />
            </Item>
          ))}
        </MobileContent>
      )}
      <DownloadButton>
        <a
          href="https://drive.google.com/uc?export=download&id=1zhRxksK--AOWSmg1J6WTZE_kzCGDGmFc"
          target="_blank"
          rel="noreferrer"
        >
          Скачать каталог
        </a>
      </DownloadButton>
    </ProjectsEl>
  );
};

export default Projects;
