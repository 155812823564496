import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { profitsItems } from "../../constants";
import expand from "../../assets/icons/expand.svg";
import classNames from "classnames";
import "./accordion.styles.css";

const AccordionItem = () => (
  <Accordion.Root
    className="AccordionRoot"
    type="single"
    defaultValue="item-1"
    collapsible
  >
    {profitsItems.map((profit) => (
      <Accordion.Item
        className="AccordionItem"
        value={profit.value}
        key={profit.title}
      >
        <AccordionTrigger>{profit.title}</AccordionTrigger>
        <AccordionContent>{profit.description}</AccordionContent>
      </Accordion.Item>
    ))}
  </Accordion.Root>
);

const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={classNames("AccordionTrigger", className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <img
          src={expand}
          alt="arrowexpand"
          className="AccordionChevron"
          aria-hidden
        />
      </Accordion.Trigger>
    </Accordion.Header>
  )
);

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
);

export default AccordionItem;
