import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Title } from "./Profits";
import { FeedbacksItems } from "../constants";

const FeedbacksEl = styled.section`
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 150px;
  margin-bottom: 120px;
  @media (max-width: 767px) {
    gap: 30px;
    margin: 130px 0 80px 0;
  }
`;
const FeedbacksTitle = styled(Title)`
  width: 50%;
  text-align: right;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 550px;
  height: 350px;

  position: relative;
  border-top: 1px solid var(--line);
  @media (max-width: 1024px) {
    min-height: 600px;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    height: unset;
    min-height: unset;
    gap: 30px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 40px 0 20px;
  @media (max-width: 767px) {
    justify-content: center;
    padding: 0 10px;
  }

  &:first-child {
    align-items: start;
    padding-top: 40px;
    @media (max-width: 819px) {
      padding-top: 20px;
    }
    @media (max-width: 575px) {
      padding-top: 10px;
    }
  }
  &:last-child {
    align-items: end;
  }
`;

const SpanLine = styled.span`
  position: absolute;
  width: 1px;
  height: 0;
  top: 0;
  right: 0;
  background-color: var(--line);
  transition: height 500ms ease-in-out;

  &.visible-0 {
    height: 70%;
  }

  &.visible-1 {
    height: 100%;
  }
`;

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  justify-self: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;

  & p {
    letter-spacing: -0.5px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  &.visible {
    opacity: 1;
  }
  @media (max-width: 575px) {
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    text-align: center;
    align-items: start;
  }
`;

const ImgWrapper = styled.div`
  border-radius: 50%;
  min-width: 100px;
  width: 100px;
  height: 100px;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 575px) {
    gap: 10px;
  }
  & div {
    display: flex;
    justify-content: space-between;
  }
  & :last-child {
    text-align: end;
  }
  & span {
    color: var(--line);
    font-size: 12px;
    font-family: var(--ff-300);
  }
`;
const Feedbacks = (props) => {
  const { screenWidth } = props;
  const [isInView, setIsInView] = useState(false);
  const [visibleSpans, setVisibleSpans] = useState([false, false, false]);
  const [visibleDivs, setVisibleDivs] = useState([false, false, false]);
  const elementRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      FeedbacksItems.forEach((_, index) => {
        setTimeout(() => {
          setVisibleSpans((prev) => {
            const newVisibleSpans = [...prev];
            newVisibleSpans[index] = true;
            return newVisibleSpans;
          });

          setTimeout(() => {
            setVisibleDivs((prev) => {
              const newVisibleDivs = [...prev];
              newVisibleDivs[index] = true;
              return newVisibleDivs;
            });
          }, 300);
        }, index * 700);
      });
    }
  }, [isInView]);
  return (
    <FeedbacksEl id="feedbacks">
      <FeedbacksTitle>Отзывы</FeedbacksTitle>
      <Content ref={elementRef}>
        {FeedbacksItems.map((item, index) => (
          <ContentWrapper key={index} data-num={index}>
            <ContentItem
              data-index={index}
              className={visibleDivs[index] ? "visible" : ""}
            >
              <ImgWrapper>
                <img src={item.img} alt={item.text} />
              </ImgWrapper>

              <Text>
                <p>{item.text}</p>
                <div>
                  <span>{item.name}</span>
                  <span>{item.type}</span>
                </div>
              </Text>
            </ContentItem>
            {(index === 0 || index === 1) && screenWidth > 575 && (
              <SpanLine
                className={visibleSpans[index] ? `visible-${index}` : ""}
              />
            )}
          </ContentWrapper>
        ))}
      </Content>
    </FeedbacksEl>
  );
};

export default Feedbacks;
