import React from "react";
import styled from "styled-components";
import logo from "../assets/icons/logo.svg";
const FooterEl = styled.footer`
  border-bottom: 1px solid var(--line);
  margin-bottom: 130px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "req add cont";
  margin-top: 30px;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr 0.9fr 0.9fr;
  }
  @media (max-width: 767px) {
    grid-template-areas:
      "req req"
      "add cont";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 150px 150px;
    margin-bottom: 100px;
  }
  @media (max-width: 575px) {
    grid-template-areas:
      "req"
      "add"
      "cont";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  @media (max-width: 575px) {
    & > div {
      padding: 15px 0;
    }
  }
`;
const Requsites = styled.div`
  grid-area: req;
  padding: 40px 10px;
  text-align: left;
  display: flex;
  align-items: start;
  border-right: 1px solid var(--line);
  & div {
    @media (max-width: 767px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      text-align: right;
    }
  }

  & img {
    cursor: pointer;
  }
  @media (max-width: 1023px) {
    padding: 30px 10px;
  }
  @media (max-width: 767px) {
    align-items: center;
    padding: 20px 10px;
    border-bottom: 1px solid var(--line);
    border-right: none;
  }
`;
const Address = styled.div`
  grid-area: add;
  display: flex;
  justify-content: center;
  padding: 40px 0;
  text-align: left;
  border-right: 1px solid var(--line);
  @media (max-width: 1023px) {
    padding: 30px 10px;
  }
  @media (max-width: 767px) {
    border: none;
    justify-content: start;
  }
  @media (max-width: 575px) {
    border-bottom: 1px solid var(--line);
  }
`;
const Contacts = styled.div`
  grid-area: cont;
  display: flex;
  justify-content: center;
  padding: 40px 0;
  text-align: left;
  @media (max-width: 1023px) {
    padding: 30px 10px;
  }
  @media (max-width: 767px) {
    justify-content: end;
    text-align: end;
  }
`;
const Subtitle = styled.h5`
  font-size: 24px;
  font-family: var(--ff);
  letter-spacing: -0.7px;
`;
const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  & span {
    display: block;
  }
  @media (max-width: 767px) {
    gap: 20px;
  }
  @media (max-width: 575px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    & span {
      display: block;
      text-align: right;
    }
  }
  @media (max-width: 424px) {
    & div {
      width: 100%;
    }
  }
`;

const Link = styled.a`
  display: block;
  cursor: pointer;
  &.orangeText {
    color: var(--orange);
  }
`;

const Footer = ({ screenWidth }) => {
  const handleClickLogo = () => {
    const section = document.getElementById("hero");
    window.scrollTo({
      top: section,
      behavior: "smooth",
    });
  };
  return (
    <FooterEl id="contacts">
      <Requsites>
        <img src={logo} alt="logo" onClick={handleClickLogo} />
        <FooterItem>
          {screenWidth > 575 && <Subtitle>Реквизиты</Subtitle>}
          <div>
            <span>ИП Султангареев Р. С.</span>
            <span>ИНН 234604899819</span>
            <span>ОГРНИП 318237500369501</span>
          </div>
        </FooterItem>
      </Requsites>
      <Address>
        <FooterItem>
          {screenWidth > 424 && <Subtitle>Адрес</Subtitle>}
          <div>
            <span>г. Краснодар, ул. </span>
            <span>Аэродромная, 19, оф. 36</span>
          </div>
        </FooterItem>
      </Address>
      <Contacts>
        <FooterItem>
          {screenWidth > 424 && <Subtitle>Контакты</Subtitle>}
          <div>
            <Link className="orangeText" href="tel:+79282050645">
              79282050645
            </Link>
            <Link href="mailto:comfortmaster.krd@gmail.com" target="_blank">
              comfortmaster.krd@gmail.com
            </Link>
          </div>
        </FooterItem>
      </Contacts>
    </FooterEl>
  );
};

export default Footer;
