import React from "react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";

import "./selectComponent.styles.css";

const SelectComponent = ({ values, handleChange, id }) => (
  <Select.Root
    onValueChange={(value) => handleChange({ target: { id, value } })}
  >
    <Select.Trigger className="SelectTrigger" aria-label="Food">
      <Select.Value placeholder="Выберите значение" defaultValue="" />
      <Select.Icon className="SelectIcon"></Select.Icon>
    </Select.Trigger>
    <Select.Portal>
      <Select.Content className="SelectContent">
        <Select.ScrollUpButton className="SelectScrollButton"></Select.ScrollUpButton>
        <Select.Viewport className="SelectViewport">
          <Select.Group>
            {values.map((value) => (
              <SelectItem value={value} key={value}>
                {value}
              </SelectItem>
            ))}
          </Select.Group>
        </Select.Viewport>
        <Select.ScrollDownButton className="SelectScrollButton"></Select.ScrollDownButton>
      </Select.Content>
    </Select.Portal>
  </Select.Root>
);

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classnames("SelectItem", className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="SelectItemIndicator"></Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default SelectComponent;
