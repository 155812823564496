export const navigationItems = [
  {
    anchor: "projects",
    text: "Проектирование и ",
    additionalText: "Строительство",
  },
  {
    anchor: "mortgage",
    text: "Ипотека",
  },
  {
    anchor: "services",
    text: "Услуги",
  },
  {
    anchor: "contacts",
    text: "Контакты",
  },
  {
    anchor: "call",
    text: "",
    additionalText: "Позвонить 79282050645",
  },
];

export const profitsItems = [
  {
    title: "Гибкие условия",
    description:
      "Мы предложим индивидуальные условия ипотечного кредита, чтобы каждая семья смогла позволить себе строительство собственного дома. Выберите срок и размер кредита, который подходит именно вам!",
    value: "item-1",
  },
  {
    title: "Минимальные первоначальные взносы",
    description:
      "Забудьте о больших суммах, которые нужно было бы вносить вначале. У нас низкие первоначальные взносы, что делает покупку жилья доступной для всех!",
    value: "item-2",
  },
  {
    title: "Скорое одобрение",
    description:
      "Мы знаем, что время – деньги! Наша команда экспертов быстро обработает вашу заявку, и вы получите решение в кратчайшие сроки",
    value: "item-3",
  },
  {
    title: "Поддержка на всех этапах",
    description:
      "Мы готовы помочь вам не только в получении ипотеки, но и в выборе участка, проектировании и строительстве. Наши специалисты предложат лучшие решения на каждом этапе!",
    value: "item-4",
  },
  {
    title: "Консультации профессионалов",
    description:
      "Наша команда состоит из опытных экспертов, готовых ответить на все ваши вопросы и объяснить все нюансы ипотечного процесса",
    value: "item-5",
  },
];

export const howItWorksItems = [
  {
    title: "Заявка",
    description: "Заполните форму на нашем сайте или позвоните нам",
  },
  {
    title: "Консультация",
    description:
      " Наши специалисты свяжутся с вами для обсуждения ваших потребностей и предложат оптимальные варианты",
  },
  {
    title: "Ипотека",
    description:
      "Получите одобрение ипотеки и начните строительство вашего нового дома!",
  },
];

export const projectsItems1 = [
  {
    text: "Проектирование дома позволяет учесть ваши предпочтения и потребности, продумать количество и функциональность комнат.",
  },
  {
    src: require("./assets/images/paint.jpg"),
  },
  {
    src: require("./assets/images/house.jpg"),
  },
  {
    src: require("./assets/images/angle.jpg"),
  },
  {
    text: "Начав с профессионального проекта, вы создадите дом, в котором будет комфортно жить.",
    additionalText:
      "Наличие проекта ускоряет строительство, минимизируя ошибки и задержки, так как все участники процесса будут работать по единому плану. ",
  },
  {
    src: require("./assets/images/wodden.jpg"),
  },
];

export const projectsItems2 = [
  {
    text: "Строительство дачи — это создание вашего личного уголка отдыха на природе. Мы предлагаем полный спектр услуг от проектирования до возведения и отделки. Ваша дача будет комфортной, стильной и идеально приспособленной для отдыха и восстановления сил.",
  },
  {
    src: require("./assets/images/triangled.jpg"),
  },
  {
    src: require("./assets/images/sauna.jpg"),
  },
  {
    src: require("./assets/images/roof.jpg"),
  },
  {
    text: "Также мы занимаемся строительством гаражей, коммерческих зданий и беседок. ",
    additionalText:
      "Независимо от масштаба проекта, мы предлагаем комплексный подход: от создания индивидуального проекта до его реализации. Гарантируем качество, долговечность и соблюдение сроков. Создадим для вас функциональные и эстетичные решения, которые будут отвечать всем вашим потребностям.",
  },
  {
    src: require("./assets/images/garage.jpg"),
  },
];

export const projectsForMobile = [
  {
    count: "01",
    title: "Дом",
    src: require("./assets/images/house.jpg"),
    description:
      "Проектирование дома позволяет учесть ваши предпочтения и потребности, продумать количество и функциональность комнат.",
  },
  {
    count: "02",
    title: "Дача",
    src: require("./assets/images/triangled.jpg"),
    description:
      "Ваша дача будет комфортной, стильной и идеально приспособленной для отдыха и восстановления сил.",
  },
  {
    count: "03",
    title: "Баня",
    src: require("./assets/images/sauna.jpg"),
    description:
      "Построим баню, где будут сочетаться уют и стиль , создавая идеальные условия для полного расслабления и восстановления.",
  },
  {
    count: "04",
    title: "Гараж",
    src: require("./assets/images/garage.jpg"),
    description:
      "Гараж будет не просто местом для хранения автомобиля, но и функциональным и стильным пространством, идеально подходящим для всех ваших нужд.",
  },
  {
    count: "05",
    title: "Беседка",
    src: require("./assets/images/roof.jpg"),
    description:
      "Беседка будет прекрасным местом для отдыха на открытом воздухе",
  },
  {
    description:
      " Независимо от масштаба проекта, мы предлагаем комплексный подход: от создания индивидуального проекта до его реализации. Гарантируем качество, долговечность и соблюдение сроков. Создадим для вас функциональные и эстетичные решения, которые будут отвечать всем вашим потребностям.",
    src: require("./assets/images/paint.jpg"),
  },
];

export const servicesItems = [
  {
    title: "Заливка фундамента",
    items: [
      "- Тщательная подготовка участка",
      "- Контроль каждого этапа заливки",
      "- Использование качественных материалов",
    ],
  },
  {
    title: "Кирпичная кладка",
    items: [
      "- Качественные кирпичи и растворы",
      "- Точная и аккуратная кладка",
      "- Эстетичный внешний вид стен",
    ],
  },
  {
    title: "Внутренняя отделка",
    items: [
      "- Уникальные дизайнерские решения",
      "- Высокое качество отделки",
      "- Комфорт и уют в каждом помещении",
    ],
  },
  {
    title: "Кровля",
    items: [
      "- Надежные кровельные материалы",
      "- Качественный монтаж ",
      "- Долговечная защита от непогоды",
    ],
  },
];

export const FeedbacksItems = [
  {
    img: require("./assets/images/user1.png"),
    text: `"Благодаря профессиональной команде строителей, наш дом был построен в срок и с соблюдением всех качественных стандартов. Мы очень довольны результатом!"`,
    name: "Марк",
    type: "Строительство загородного дома",
  },
  {
    img: require("./assets/images/user2.png"),
    text: `"Очень довольны выбором строительной компании для постройки нашего дома. Профессионализм, внимательное отношение к деталям и ответственный подход к работе – все это мы видели во время строительства. Рекомендуем!"`,
    name: "Павел",
    type: "Строительство гаража",
  },
  {
    img: require("./assets/images/user3.png"),
    text: `"Замечательная компания, которая отлично знает свое дело и предоставляет высококачественные услуги по строительству частных домов. Наш дом выглядит прекрасно, и мы рады, что сотрудничали именно с ними."`,
    name: "Анастасия",
    type: "Строительство загородного дома",
  },
];

export const settingsItems = [
  {
    type: "text",
    title: "Тип постройки",
    placeholder: "Дом, баня, гараж ...",
    id: "building",
    values: ["Дом", "Баня", "Гараж", "Беседка"],
  },
  {
    type: "number",
    title: "Площадь кв.м",
    placeholder: "150",
    id: "area",
    values: ["от 0 до 50", "от 50 до 100", "от 100 до 300", "более 300"],
  },
  {
    type: "text",
    title: "Фундамент",
    placeholder: "Лента,плита ...",
    id: "floor",
    values: ["Лента", "Плита", "Сваи", "Блоки"],
  },
  {
    type: "text",
    title: "Материал стен",
    placeholder: "Кирпич, монолит, пеноблок ...",
    id: "walls",
    values: ["Кирпич", "Монолит", "Газоблок", "Пеноблок"],
  },
  {
    type: "text",
    title: "Крыша",
    placeholder: "Профлист, мягкая крыша ...",
    id: "roof",
    values: ["Мягкая кровля", "Профлист", "Плоская крыша"],
  },
  {
    type: "text",
    title: "Способ оплаты",
    placeholder: "Ипотека, наличные ...",
    id: "payments",
    values: ["Ипотека", "Наличные", "Безналичная оплата"],
  },
];
