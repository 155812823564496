import React from "react";
import styled from "styled-components";
import { Title } from "./Profits";
import Form from "../components/Form";
import mortgage1 from "../assets/images/mortgage1.jpg";
import mortgage2 from "../assets/images/mortgage2.jpg";
import arrow from "../assets/icons/arrow.svg";
const MortgageEl = styled.section`
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-top: 200px;

  @media (max-width: 767px) {
    gap: 60px;
    margin-top: 120px;
  }
  @media (max-width: 575px) {
    gap: 30px;
    margin-bottom: 80px;
  }
`;
const MortgageTitle = styled(Title)`
  width: 50%;
  text-align: right;
`;
const Content = styled.div`
  display: flex;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--line);
  width: 50%;
  padding-right: 10px;
  & h5 {
    padding-top: 55px;
    padding-left: 10px;
    margin-bottom: 120px;
    font-size: 24px;
    line-height: 1.3;
    text-align: left;
    max-width: 555px;
    @media (max-width: 767px) {
      width: 100%;
      padding: 20px 0;
      margin-bottom: 30px;
      align-self: center;
      text-align: center;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ImgsContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  & :first-child {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & :last-child {
    width: 60%;
    height: 100%;
    align-self: flex-end;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const Consultation = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 80px;
  text-align: left;
  @media (max-width: 767px) {
    gap: 40px;
    & img {
      width: 184px;
      height: 74px;
    }
  }

  @media (max-width: 575px) {
    gap: 15px;
    & img {
      width: 78px;
      height: 31px;
    }
  }
`;

const ConsTitle = styled(Title)`
  @media (max-width: 767px) {
    font-size: 28px;
  }
  @media (max-width: 575px) {
    font-size: 18px;
  }
`;
const Mortgage = () => {
  return (
    <MortgageEl id="mortgage">
      <MortgageTitle>Ипотека</MortgageTitle>
      <Content>
        <FormWrapper>
          <h5>
            Все дома можно приобрести в ипотеку. Заполните заявку и наш менеджер
            свяжется с вами.
          </h5>
          <Form />
        </FormWrapper>
        <ImgsContent>
          <img src={mortgage1} alt="mortgage1" />
          <img src={mortgage2} alt="mortgage2" />
        </ImgsContent>
      </Content>
      <Consultation>
        <img src={arrow} alt="arrow" />
        <ConsTitle>
          Бесплатная консультация <br />с менеджером
        </ConsTitle>
      </Consultation>
    </MortgageEl>
  );
};

export default Mortgage;
