import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import message from "../../assets/icons/message.png";
import * as Dialog from "@radix-ui/react-dialog";
import "./actionButton.styles.css";
import settings from "../../assets/icons/settings.png";
import { settingsItems } from "../../constants";
import SelectComponent from "../select/SelectComponent";

const ActionButtonEl = styled.button`
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 1000;
  background-color: var(--orange);
  height: 90px;
  width: 90px;
  border-radius: 50%;
  animation: pulse 2500ms infinite ease-in-out;
  & img {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 767px) {
    right: 20px;
    height: 60px;
    width: 60px;
    & img {
      width: 35px;
      height: 35px;
    }
  }
`;

const Settings = styled.fieldset`
  & label {
    cursor: pointer;
  }
  & img {
    cursor: pointer;
    height: 30px;
    width: 30px;
  }
`;

const initialState = {
  name: "",
  phone: "",
  building: "",
  area: "",
  floor: "",
  walls: "",
  roof: "",
  payment: "",
};

const ActionButton = () => {
  const [user, setUser] = useState(initialState);
  const [isShowAdvancedSettings, setIsShowAdvancedSettings] = useState(false);
  const [isReadyToSend, setIsReadyToSend] = useState(false);
  const [wasSend, setWasSend] = useState(false);
  const [wasError, setWasError] = useState(false);
  const closeDialogRef = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();
    let messageLines = [];
    if (user.area) messageLines.push(`Площадь: ${user.area} кв.м \n`);
    if (user.building) messageLines.push(`Тип строения: ${user.building} \n`);
    if (user.floor) messageLines.push(`Пол: ${user.floor} \n`);
    if (user.payment) messageLines.push(`Способ оплаты: ${user.payment} \n`);
    if (user.roof) messageLines.push(`Крыша: ${user.roof} \n`);
    if (user.walls) messageLines.push(`Стены: ${user.walls} \n`);
    const message = messageLines.join("");

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_KEY,
        process.env.REACT_APP_FORM_TEMPLATE,
        {
          to_name: "lid.comfortm.gmail.com",
          message: message,
          phone: user.phone,
          from_name: user.name,
        },
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(() => {
        setWasSend(true);
        setTimeout(() => {
          if (closeDialogRef.current) {
            closeDialogRef.current.click();
            setWasSend(false);
          }
        }, 3000);
      })
      .catch((error) => {
        setWasError(true);
      });
  };

  useEffect(() => {
    if (user.name.length > 1 && user.phone.length > 10) {
      setIsReadyToSend(true);
    } else {
      setIsReadyToSend(false);
    }
  }, [user.name, user.phone]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "phone") {
      const phoneValue = value.replace(/\D/g, "");

      setUser((prevState) => ({
        ...prevState,
        [id]: phoneValue,
      }));
    } else {
      setUser((prevState) => ({
        ...prevState,
        [id]: value || "",
      }));
    }
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <ActionButtonEl>
          <img src={message} alt="text" />
        </ActionButtonEl>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          {!wasSend ? (
            <>
              <Dialog.Title className="DialogTitle">
                Обратная связь
              </Dialog.Title>

              <Dialog.Description className="DialogDescription">
                Оставьте заявку и мы обязательно с вами свяжемся. <br />
                Можете заполнить дополнительные параметры, чтобы мы могли
                проконсультировать вас более подробно*
              </Dialog.Description>
              <fieldset className="Fieldset">
                <label className="Label" htmlFor="name">
                  Имя<span>*</span>
                </label>
                <input
                  className="Input"
                  id="name"
                  placeholder="Иван Иванов"
                  onChange={handleChange}
                  value={user.name}
                />
              </fieldset>
              <fieldset className="Fieldset">
                <label className="Label" htmlFor="phone">
                  Номер телефона<span>*</span>
                </label>
                <input
                  className="Input"
                  id="phone"
                  placeholder="79991231123"
                  onChange={handleChange}
                  value={user.phone}
                />
              </fieldset>
              <Settings
                className="Fieldset"
                onClick={() => {
                  setIsShowAdvancedSettings(!isShowAdvancedSettings);
                }}
              >
                <label
                  className="Label"
                  htmlFor="add"
                  onClick={() => {
                    setIsShowAdvancedSettings(!isShowAdvancedSettings);
                  }}
                >
                  Расширенная
                  <br />
                  информация
                </label>
                <img src={settings} alt="settings" />
              </Settings>
              {isShowAdvancedSettings && (
                <>
                  {settingsItems.map((setting) => (
                    <>
                      <fieldset className="Fieldset" key={setting.placeholder}>
                        <label className="Label" htmlFor={setting.id}>
                          {setting.title}
                        </label>
                        <SelectComponent
                          id={setting.id}
                          handleChange={handleChange}
                          values={setting.values}
                        />
                      </fieldset>
                    </>
                  ))}
                </>
              )}
              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild>
                  <button
                    className="Button green"
                    disabled={!isReadyToSend}
                    onClick={sendEmail}
                  >
                    Отправить заявку
                  </button>
                </Dialog.Close>
              </div>
            </>
          ) : (
            <>
              <Dialog.Title
                style={{
                  fontSize: "28px",
                  lineHeight: 1,
                  textAlign: "center",
                  letterSpacing: "-1px",
                  padding: "40px 10px",
                }}
              >
                {wasError ? (
                  <>
                    Возникла ошибка.
                    <br />
                    Попробуйте позвонить
                  </>
                ) : (
                  <>
                    Спасибо за заявку.
                    <br />С вами скоро свяжется менеджер
                  </>
                )}
              </Dialog.Title>
            </>
          )}
          <Dialog.Close asChild>
            <button
              className="IconButton"
              aria-label="Close"
              ref={closeDialogRef}
            >
              х
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ActionButton;
