import React from "react";
import styled from "styled-components";
import yellowHat from "../assets/images/yellowhat.jpg";
import arrow from "../assets/icons/arrow.svg";
import herohouse from "../assets/images/herohouse.jpg";

const HeroEl = styled.section`
  min-height: calc(100vh - 108px);
  height: calc(100vh - 108px);
  margin-top: 108px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100%;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "first second"
      "third third";
    min-height: calc(600px - 70px);
    height: calc(100vh - 70px);
    margin-top: 70px;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-template-rows: 33% 67%;
  }
`;

const HatImg = styled.div`
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width: 1023px) {
      grid-area: first;
      width: 100%;
      height: 100%;
      object-position: bottom;
    }
  }
`;

const HeroInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--black);
  & h1 {
    padding: 35px 30px 0 30px;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 108%;
    text-align: left;
    letter-spacing: -1.2px;
    font-family: var(--ff-500);
  }
  & div {
    display: flex;
    border-top: 1px var(--line) solid;
    margin: 0 10px;

    & :first-child {
      border-right: 1px var(--line) solid;
    }

    & p {
      text-align: left;
      font-size: 16px;
      padding: 20px 10px;
      width: 50%;
      line-height: 0.95;
    }
  }
  @media (max-width: 1023px) {
    grid-area: second;
    height: auto;
  }
  @media (max-width: 575px) {
    background-image: url(${require("../assets/images/yellowhat.jpg")});
    background-size: cover;
    background-position: bottom;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }

    & > * {
      position: relative;
      z-index: 2;
    }
    & h1 {
      padding: 15px 30px 0 30px;
      font-size: 32px;
      text-align: center;
    }

    & div {
      & p {
        font-size: 14px;
        padding: 10px 10px;
        width: 50%;
        line-height: 1;
      }
    }
  }
`;
const HeroServices = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    flex-direction: row;
    grid-area: third;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

const Mortgage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-color: var(--white);
  padding-top: 40px;
  height: 50%;
  & h2 {
    font-size: 40px;
    text-transform: uppercase;
    line-height: 108%;
    text-align: left;
    font-family: var(--ff-400);
    color: var(--black);
  }
  & p {
    color: var(--black);
    padding: 0 48px;
    line-height: 1.2;
    text-indent: 53%;
    text-align: left;
  }
  @media (max-width: 1023px) {
    height: 100%;
    width: 50%;
  }
  @media (max-width: 575px) {
    width: 100%;
    gap: 20px;
    padding-top: 20px;
    & h2 {
      font-size: 32px;
    }
    & p {
      text-indent: 50%;
      padding: 0 24px;
    }
  }
`;

const AngledText = styled.div`
  position: absolute;
  display: inline-block;
  right: 10px;
  bottom: 15px;
  & span {
    display: block;
    color: var(--black);
    font-size: 10px;
    letter-spacing: -0.8px;
  }
  & :last-child {
    position: absolute;
    transform: rotate(90deg);
    right: -5px;
    top: 0;
    transform-origin: right top;
  }
`;
const ArrowContainer = styled.div`
  flex: 1;
  display: flex;
  align-self: flex-start;
  align-items: center;
  width: 230px;
  & img {
    width: 100%;
    &:hover {
      scale: 1.1;
      transform: translateX(10px);
      transition: all ease-in-out 300ms;
      cursor: pointer;
    }
  }
  @media (max-width: 575px) {
    width: 180px;
    height: 90px;
    & img {
      &:hover {
        scale: 1.1;
        transform: translateX(8px);
      }
    }
  }
`;

const Projects = styled.div`
  position: relative;
  height: 50%;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  & h3 {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    color: var(--orange);
    font-size: 40px;
    font-family: var(--ff-500);
    &:hover {
      scale: 1.1;
      transition: all ease-in-out 300ms;
      cursor: pointer;
    }
  }
  @media (max-width: 1023px) {
    height: 100%;
    max-height: 100%;
    width: 50%;
  }
  @media (max-width: 575px) {
    width: 100%;
    height: 50%;
    & h3 {
      font-size: 32px;
    }
  }
`;

const Hero = (props) => {
  const { screenWidth } = props;

  const handleScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section && sectionId !== "call") {
      const elementPosition =
        section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 108;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <HeroEl id="hero">
      {screenWidth >= 576 && (
        <HatImg>
          <img src={yellowHat} alt="yellowHat" />
        </HatImg>
      )}
      <HeroInfo>
        <h1>Ваш дом- наша забота!</h1>
        <div>
          <p>
            Мечтаете о своем уютном доме, где будут собираться ваша семья и
            друзья?
          </p>
          <p>
            Теперь это возможно с нашей уникальной ипотечной программой на
            строительство частного дома!
          </p>
        </div>
      </HeroInfo>
      <HeroServices>
        <Mortgage>
          <h2>Ипотека</h2>
          <p>
            Осуществите мечту о собственном доме с нашей ипотекой на
            строительство!
          </p>
          <ArrowContainer
            onClick={() => {
              handleScrollTo("mortgage");
            }}
          >
            <img src={arrow} alt="arrow" />
          </ArrowContainer>

          <AngledText>
            <span>Быстрое одобрение</span>
            <span>Быстрое одобрение</span>
          </AngledText>
        </Mortgage>
        <Projects>
          <img src={herohouse} alt="herohouse" />
          <h3
            onClick={() => {
              handleScrollTo("projects");
            }}
          >
            Проекты
          </h3>
        </Projects>
      </HeroServices>
    </HeroEl>
  );
};

export default Hero;
