import React from "react";
import styled from "styled-components";
import AccordionItem from "../components/accordionProfits/AccordionItem";
import profitimg from "../assets/images/profitimg.jpg";

const ProfitsItem = styled.section`
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 150px;
  @media (max-width: 767px) {
    gap: 30px;
    margin-top: 80px;
  }
`;
export const Title = styled.h2`
  font-family: var(--ff-500);
  text-transform: uppercase;
  font-size: 40px;
  line-height: 108%;
  letter-spacing: -1.2px;
  @media (max-width: 575px) {
    font-size: 32px;
  }
  @media (max-width: 374px) {
    font-size: 28px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  max-height: 415px;
  gap: 10px;
  & > div {
    width: 50%;
  }
  @media (max-width: 767px) {
    max-height: unset;
    flex-direction: column;

    gap: 20px;
    & > div {
      width: 100%;
    }
  }
`;
const ProfitImg = styled.div`
  & img {
    width: auto;
    height: 100%;
    object-fit: cover;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`;

const Profits = () => {
  return (
    <ProfitsItem id="profits">
      <Title>Почему мы?</Title>
      <Content>
        <AccordionItem />
        <ProfitImg>
          <img src={profitimg} alt="profitimg" />
        </ProfitImg>
      </Content>
    </ProfitsItem>
  );
};

export default Profits;
