import React from "react";

const Map = () => {
  const containerStyle = {
    position: "relative",
    overflow: "hidden",
  };

  const linkStyle = {
    color: "#eee",
    fontSize: "12px",
    position: "absolute",
  };

  const linkStyleTop = {
    ...linkStyle,
    top: "0px",
  };

  const linkStyleBottom = {
    ...linkStyle,
    top: "14px",
  };

  const iframeStyle = {
    position: "relative",
    filter: "grayscale(100%)",
    width: "100%",
    height: "400px",
    border: "none",
  };

  return (
    <div>
      <div style={containerStyle}>
        <a
          href="https://yandex.ru/maps/35/krasnodar/?utm_medium=mapframe&utm_source=maps"
          style={linkStyleTop}
        >
          Краснодар
        </a>
        <a
          href="https://yandex.ru/maps/35/krasnodar/house/aerodromnaya_ulitsa_19/Z0EYfwFoQUUOQFpvfXx1d3lmZg==/?ll=38.969650%2C45.044027&utm_medium=mapframe&utm_source=maps&z=16.13"
          style={linkStyleBottom}
        >
          Аэродромная улица, 19 — Яндекс Карты
        </a>
        <iframe
          src="https://yandex.ru/map-widget/v1/?ll=38.969820%2C45.046988&mode=search&oid=174724166850&ol=biz&z=17.13"
          height="400"
          frameBorder="1"
          allowFullScreen={true}
          style={iframeStyle}
        ></iframe>
      </div>
    </div>
  );
};

export default Map;
