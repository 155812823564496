import React from "react";
import styled from "styled-components";
import { Title } from "./Profits";
import { servicesItems } from "../constants";

const ServicesEl = styled.section`
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-top: 200px;
  @media (max-width: 875px) {
    gap: 60px;
    margin-top: 120px;
  }
  @media (max-width: 575px) {
    gap: 40px;
    margin-top: 80px;
  }
`;
const ServicesTitle = styled(Title)`
  width: 50%;
  text-align: right;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-top: 1px solid var(--line);
  & :last-child {
    border-right: none;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const ServiceItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 370px;
  text-align: left;
  padding: 35px 20px 0 10px;
  border-right: 1px solid var(--line);
  @media (max-width: 767px) {
    height: 220px;
    padding: 20px 20px 10px 10px;
    border: none;
  }
  @media (max-width: 575px) {
    height: unset;
    gap: 10px;
    text-align: center;
  }
  & h4 {
    font-size: 24px;
    color: var(--orange);
    font-family: var(--ff);
  }
  & div {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
  }
`;
const Services = () => {
  return (
    <ServicesEl id="services">
      <ServicesTitle>Услуги</ServicesTitle>
      <Content>
        {servicesItems.map((service) => (
          <ServiceItem key={service.title}>
            <h4>{service.title}</h4>
            <div>
              {service.items.map((item) => (
                <span key={item}>{item}</span>
              ))}
            </div>
          </ServiceItem>
        ))}
      </Content>
    </ServicesEl>
  );
};

export default Services;
